import React, { useEffect, useState, lazy, Suspense } from "react";
import Header from "./Header";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import OTPInput, { ResendOTP } from "otp-input-react";
import Footer from "./Footer";
import MapboxRobot from "./MapboxRobot";
import Instruction from "../modals/Instruction";
const Watchvideo = lazy(() => import("../modals/Watchvideo"));

function Congratulations({ orderDetails, otp, eta, etaLoader, isLoading }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderID } = useParams();
  const [open, setOpen] = React.useState(false);
  const [openthree, setopenthree] = useState(false);
  const [opentwo, setOpentwo] = React.useState(false);
  const navigate = useNavigate();
  const [inputOTP, setInputOTP] = useState("");
  const handleOpenthree = () => setopenthree(true);
  const [openfour, setopenfour] = React.useState(false);
  const handleOpenfour = () => {
    setopenfour(true);
  };

  var rString = "";

  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
  };

  const loaderStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderTop: "4px solid #000",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
  };

  const keyframesAnimation = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    bgcolor: "#ffffff",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    paddingTop: "11px",
    paddingBottom: "0px",
    border: "none",
    paddingRight: "0px",
    paddingLeft: "0px",
    zIndex: "999999999999999999999999",
  };

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleClosetwo = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpentwo(false);
  };
  const setupOrderStatus = (status) => {
    let response = {};
    switch (status) {
      case 5:
        response.text = "Order Picked Up";
        response.subText = "";
        response.imageSrc = "/assets/images/image_dummy.png";

      case 6:
        response.text = "Order Enroute Delivery Location";
        response.imageSrc = "/assets/images/noun-location-5474996.svg";
        return response;
      case 3:
        response.text = "Order reached delivery location";
        response.subText = "";
        response.imageSrc = "/assets/images/noun-location-5474996.svg";
      case 4:
        response.text = "Congratulations ! Your order has been completed";
        response.imageSrc = "/assets/images/noun-location-5474996.svg";
    }
  };

  const handleOpen = () => setOpen(true);

  const handleopentwo = () => setOpentwo(true);
  const [newstring, setnewstring] = useState();
  // useEffect(() => {
  //   // generateBarCode(orderData.state.newstring)
  //   const today = new Date();
  //   const yyyy = today.getFullYear();
  //   let mm = today.getMonth() + 1; // Months start at 0!
  //   let dd = today.getDate();
  //   let hrs = today.getHours(); // => 9
  //   let mins = today.getMinutes(); // =>  30
  //   var ampm = hrs >= 12 ? "pm" : "am";
  //   hrs = hrs % 12;
  //   hrs = hrs ? hrs : 12;
  //   if (dd < 10) {
  //     dd = "0" + dd;
  //   }
  //   if (mm < 10) {
  //     mm = "0" + mm;
  //   }
  //   if (hrs < 10) {
  //     hrs = "0" + hrs;
  //   }
  //   if (mins < 10) {
  //     mins = "0" + mins;
  //   }
  //   const formattedToday =
  //     mm +
  //     "-" +
  //     dd +
  //     "-" +
  //     yyyy +
  //     "\xa0\xa0\xa0\xa0\xa0" +
  //     hrs +
  //     ":" +
  //     mins +
  //     "\xa0\xa0" +
  //     ampm;
  //   document.getElementById("DATE").innerHTML = formattedToday;
  // }, []);
  let mapheight = "327px";

  useEffect(() => {
    if (document.getElementById("Estimated_time")) {
      var offsetHeight = document.getElementById("Estimated_time").offsetHeight;
    }

    mapheight = mapheight - offsetHeight + "px";
  }, [mapheight, otp]);

  function generateBarCode(qrcode) {
    var url =
      "https://api.qrserver.com/v1/create-qr-code/?data=" +
      qrcode +
      "&amp;size=50x50";
    $("#barcode").attr("src", url);
    return rString;
  }

  const getorderpage = (orderDetails) => {
    if (orderDetails?.orderStatus === "Order is delivered (Complete)") {
      navigate("/orderdelivered", { state: { orderDetails, orderID } });
    } else if (orderDetails?.orderStatus === "Order is Cancelled") {
      navigate("/ordercancel", { state: { orderDetails, orderID } });
    }
  };
  const handleKeyDown = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div className="MainWhole-wrapper-container">
        <Header orderDetails={orderDetails} open={open} opentwo={opentwo} />
        <div className="Congratulation-Main-wrapper">
          <div className="h-[49.5%] min-h-[450px] flex flex-col gap-6 justify-evenly">
            <div className="!p-4 mx-3 bg-white border-2 rounded-lg border-ottonomyBlue Congratulation-Main-wrapper_inner sm:!p-5">
              <div className="flex flex-col">
                <p className="Congratulation-Main-wrapper_p">
                  An Ottobot will be delivering your order from{" "}
                  <span> Chick-fil-A.</span>
                </p>
              </div>

              <div className="flex justify-center gap-[10px] congratulations_button_wrapper">
                <button
                  className="rounded-lg bg-ottonomyBlue congratulations_page_instruction_btn py-1.5 px-2.5 sm:px-[30px]"
                  onClick={handleOpenthree}
                >
                  Instructions
                </button>
                <button
                  className="rounded-lg bg-ottonomyBlue congratulations_page_instruction_btn py-1.5 px-2.5 sm:px-[30px]"
                  onClick={handleOpenfour}
                >
                  Watch Video
                </button>
              </div>
            </div>

            <div className="Congratulation-page-order-wrapper-below-QR">
              {/* <div>
              <h5>OrderID</h5>
              {orderID}
            </div> */}
              <div>
                <h5>Robot</h5>
                <p> Ottobot </p>
              </div>
            </div>

            <div className="Congratulations_page_QRcode_pin_wrapper">
              <h3> PIN to access your order</h3>
              <div className="QR-Code-main-inner">
                <OTPInput
                  value={localStorage.getItem("pin")}
                  inputClassName="readonly-input"
                  onChange={setInputOTP}
                  autoFocus
                  OTPLength={4}
                  onKeyDown={handleKeyDown}
                  otpType="number"
                  disabled={false}
                  className="Congratulation_page_otp_input"
                />
              </div>
            </div>
          </div>

          <Modal
            open={open}
            // BackdropProps={false}
            onHide={handleClose}
            // onClose={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="QR-Code-wrapper-whole">
                <div className="QR-Code-wrapper-whole-inner-one">
                  <h3> Scan QR Code</h3>
                  <div
                    className="Sorry_div_wrapper_inner_div"
                    style={{ paddingBottom: "0px" }}
                    onClick={handleClose}
                  >
                    <HighlightOffIcon
                      style={{ fontSize: "35px", margin: "5px" }}
                    />
                    <p style={{ fontSize: "17px" }}>Close</p>
                  </div>
                </div>
                <p style={{ marginBottom: "12px" }}>
                  {" "}
                  Show the QR code to the camera
                </p>
                <div className="QR-Code-main-inner">
                  {generateBarCode(orderDetails?.qrcode)}
                  <img
                    id="barcode"
                    src="https://api.qrserver.com/v1/create-qr-code/?data=Congratulation&amp;size=100x100"
                    alt=""
                    title="Congratualtions"
                    width="500"
                    height="500"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={opentwo}
            onHide={handleClosetwo}
            backdrop="static"
            keyboard={false}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="QR-Code-wrapper-whole">
                <div className="QR-Code-wrapper-whole-inner-one">
                  <h3> Your PIN</h3>
                  <div
                    className="Sorry_div_wrapper_inner_div"
                    style={{ paddingBottom: "0px" }}
                    onClick={handleClosetwo}
                  >
                    <HighlightOffIcon
                      style={{ fontSize: "35px", margin: "5px" }}
                    />
                    <p style={{ fontSize: "17px" }}>Close</p>
                  </div>
                </div>
                <p style={{ marginBottom: "12px" }}>
                  {" "}
                  Enter the PIN on the Top Screen
                </p>
                <div className="QR-Code-main-inner">
                  <OTPInput
                    value={otp}
                    onChange={setInputOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    className="Cart_page_otp_input"
                  />
                </div>
              </div>
            </Box>
          </Modal>

          <div
            className="Congralutions_page_map_div"
            // style={isLoading ? {}}
            id="mapDiv"
          >
            {/* <Maps/> */}
            {/* {isLoading ? (
              <div style={loaderContainerStyle}>
                <style>{keyframesAnimation}</style>
                <div style={loaderStyle}></div>
              </div>
            ) : (
              <> */}
            {orderDetails?.orderDropLocation.positionY === undefined ||
              orderDetails?.orderDropLocation.positionX === undefined ||
              orderDetails?.orderPickupLocation.positionY === undefined ||
              orderDetails?.orderPickupLocation.positionX === undefined ||
              orderDetails?.orderStatus === "Order is Cancelled" ||
              (orderDetails?.orderStatus === "Order is delivered (Complete)" &&
                getorderpage(orderDetails))}
            <MapboxRobot
              orderDropLocation={orderDetails?.orderDropLocation}
              orderPickupLocation={orderDetails?.orderPickupLocation}
              robotLocation={orderDetails?.robotLocation}
            />
            {/* </> */}
            {/* )} */}
          </div>

          <div className="Order_Details_div"></div>
        </div>
      </div>
      <Instruction
        openthree={openthree}
        setopenthree={setopenthree}
        handleOpenthree={handleOpenthree}
      />
      <Suspense
        fallback={
          <div style={loaderContainerStyle}>
            <style>{keyframesAnimation}</style>
            <div style={loaderStyle}></div>
          </div>
        }
      >
        {openfour && (
          <Watchvideo
            openfour={openfour}
            setopenfour={setopenfour}
            handleOpenfour={handleOpenfour}
          />
        )}
      </Suspense>
      <Footer eta={Math.ceil(eta / 60)} etaLoader={etaLoader} />
    </>
  );
}

export default Congratulations;
