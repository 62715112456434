import axios from "axios";

export const getdeliverytime = (orderId) => {
  return axios
    .get(
      `${process.env.REACT_APP_HAMMERHEAD_URL}/getOrdersETA/${orderId}`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getOrderDetails = (orderID) => {
  return axios
    .post(
      `${process.env.REACT_APP_HAMMERHEAD_URL}/order/details`,
      {
        orderId: orderID,
      },
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzkwMjY4ZTE1YWZjYzA5Y2M3Njc1MzYiLCJpYXQiOjE2ODkxMzEzNjF9.W3SdI4Yi6AinAy8da_qgJXwps4w28mnCNocHWg-nVt0`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getmaplist = (token) => {
  return axios
    .get(`${process.env.REACT_APP_HAMMERHEAD_URL}/map/v1/getMapList`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};
