import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { instructionData } from "../Data/Intructions";

function Instruction(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    height: 600,
  };

  const handleClosethree = (reason) => {
    if (reason && reason === "backdropClick") return;
    props.setopenthree(false);
  };
  return (
    <>
      <Modal
        open={props.openthree}
        onHide={handleClosethree}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Help_modal_wrapper">
            <div className="Modal_Header d_flex_between">
              <h3> Instruction </h3>
              <button onClick={handleClosethree}> Close[x] </button>
            </div>
            <div className="Modal_body Intrusction_modal px-3 pt-3">
              <ol className="list-decimal font-semibold text-base">
                <li>
                  Let the robot come to a halt at your selected drop location.
                </li>
                <li>
                  At the Front Top of the robot, you will see there’s a small
                  touchscreen.
                </li>
                <li>
                  Enter the 4-digit PIN for your order to open the cabin
                  containing your order.
                </li>
                <li>Pick your order from the cabin and close the cabin.</li>
              </ol>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Instruction;
