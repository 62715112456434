import { useState, useEffect } from "react";
import { FiNavigation2 } from "react-icons/fi";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

function MapboxRobot(props) {
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [robotMarker, setRobotMarker] = useState(null);
  const [dropMarker, setDropMarker] = useState(null);
  const [pickupMarker, setPickupMarker] = useState(null);
  const [dropLocationPopup, setDropLocationPopup] = useState(null);
  const [userControllingMap, setUserControllingMap] = useState(false);

  mapboxgl.accessToken =
    "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

  const robotMarkerDiv = document.createElement("div");
  robotMarkerDiv.style.width = "35px";
  robotMarkerDiv.style.height = "35px";
  // robotMarkerDiv.style.backgroundImage =
  //   "url(/assets/images/robot-icon-main.svg)";
  robotMarkerDiv.style.borderRadius = "100%";

  const dropMarkerDiv = document.createElement("div");
  dropMarkerDiv.style.width = "35px";
  dropMarkerDiv.style.height = "35px";
  dropMarkerDiv.style.backgroundImage =
    "url(/assets/images/robot_dropOff_main.svg)";
  dropMarkerDiv.style.borderRadius = "100%";

  const pickupMarkerDiv = document.createElement("div");
  pickupMarkerDiv.style.width = "35px";
  pickupMarkerDiv.style.height = "35px";
  pickupMarkerDiv.style.backgroundImage =
    "url(/assets/images/PickUpLocation-icon.svg)";
  pickupMarkerDiv.style.borderRadius = "100%";

  useEffect(() => {
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        // style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        style: "mapbox://styles/mrlakshyagupta/clmribzic029m01qx4biqdw6s",
        // center: [
        //   props?.robotLocation?.longitudeSlam,
        //   props?.robotLocation?.latitudeSlam,
        // ],
        zoom: 12,
        maxBounds: [
          [-95.55892625673143, 29.77473436824925],
          [-95.52934215261119, 29.788676939812166],
        ],
      });

      // Marker type for with/without heading
      robotMarkerDiv.style.backgroundImage =
        "url(/assets/images/robot-icon-2.svg)";
      // props?.robotLocation?.headingSlam &&
      // !!Number(process.env.REACT_APP_HEADING)
      //   ? "url(/assets/images/robot-icon-2.svg)"
      //   : "url(/assets/images/robot-icon-pickup_1.svg)";

      // Initialize robot marker
      let newRobotMarker;

      if (
        props?.robotLocation?.headingSlam &&
        !!Number(process.env.REACT_APP_HEADING)
      ) {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            props?.robotLocation?.longitudeSlam
              ? props?.robotLocation?.longitudeSlam
              : 0,
            props?.robotLocation?.latitudeSlam
              ? props?.robotLocation?.latitudeSlam
              : 0,
          ])
          .setRotation(parseFloat(props?.robotLocation?.headingSlam))
          .setRotationAlignment("map")
          .addTo(newMap);
      } else {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            props?.robotLocation?.longitudeSlam
              ? props?.robotLocation?.longitudeSlam
              : 0,
            props?.robotLocation?.latitudeSlam
              ? props?.robotLocation?.latitudeSlam
              : 0,
          ])
          .addTo(newMap);
      }

      let newDropMarker, newPickupMarker;

      newDropMarker = new mapboxgl.Marker(dropMarkerDiv)
        .setLngLat([
          parseFloat(props?.orderDropLocation?.longitude)
            ? parseFloat(props?.orderDropLocation?.longitude)
            : 0,
          parseFloat(props?.orderDropLocation?.latitude)
            ? parseFloat(props?.orderDropLocation?.latitude)
            : 0,
        ])
        .addTo(newMap);

      newPickupMarker = new mapboxgl.Marker(pickupMarkerDiv)
        .setLngLat([
          parseFloat(props?.orderPickupLocation?.longitude)
            ? parseFloat(props?.orderDropLocation?.longitude)
            : 0,
          parseFloat(props?.orderPickupLocation?.latitude)
            ? parseFloat(props?.orderDropLocation?.latitude)
            : 0,
        ])
        .addTo(newMap);

      // Create a custom popup container
      const customPopup = new mapboxgl.Popup({
        closeButton: false, // Disable the close button
        closeOnClick: false, // Prevent the popup from closing on map click
      });

      // Add the popup to the map at the specified coordinates
      customPopup
        .setLngLat([
          props?.orderDropLocation?.longitude
            ? props?.orderDropLocation?.longitude
            : 0,
          props?.orderDropLocation?.latitude
            ? props?.orderDropLocation?.latitude
            : 0,
        ])
        .addTo(newMap);

      // const newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
      //   .setLngLat([
      //     props.robotLocation.longitudeSlam,
      //     props.robotLocation.latitudeSlam,
      //   ])
      //   .setRotation(parseFloat(props.robotLocation.headingSlam))
      //   .setRotationAlignment("map")
      //   .addTo(newMap);

      newMap.on("load", () => {
        newMap.addSource("chickfila-map", {
          type: "image",
          url: `${process.env.PUBLIC_URL}/assets/images/chickfila-googlemap.png`,
          coordinates: [
            [-95.5451623748, 29.784804206],
            [-95.5346301602, 29.7849896156],
            [-95.5344598025, 29.7777023516],
            [-95.5449909197, 29.7775141246],
          ],
        });
        newMap.addLayer({
          id: "chickfila-radar-layer",
          type: "raster",
          source: "chickfila-map",
          paint: {
            "raster-fade-duration": 0,
            // "raster-opacity": 0.1,
          },
        });
      });

      let chickfilapath = [
        // [-95.5344598025, 29.7777023516],
        // [-95.5346301602, 29.7849896156],
        // [-95.5451623748, 29.784804206],
        // [-95.5449909197, 29.7775141246],
        [-95.5451623748, 29.784804206],
        [-95.5346301602, 29.7849896156],
        [-95.5344598025, 29.7777023516],
        [-95.5449909197, 29.7775141246],
      ];

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        chickfilapath[0],
        chickfilapath[0]
      );

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of chickfilapath) {
        bounds.extend(coord);
      }

      newMap.fitBounds(bounds, {
        // padding: 20,
      });

      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });

      newMap.on("wheel", () => {
        setUserControllingMap(true);
      });

      newMap.on("drag", () => {
        setUserControllingMap(true);
      });

      setRobotMarker(newRobotMarker);
      setPickupMarker(newPickupMarker);
      setDropMarker(newDropMarker);
      setDropLocationPopup(customPopup);
      setMap(newMap);
    };

    if (!map) {
      initializeMap();
    }
  }, [
    map,
    setMap,
    props.robotLocation,
    props.orderDropLocation,
    props.orderPickupLocation,
    dropMarkerDiv,
    pickupMarkerDiv,
    robotMarkerDiv,
  ]);

  useEffect(() => {
    // move robot marker
    if (robotMarker) {
      if (
        !!Number(process.env.REACT_APP_HEADING) &&
        (props?.robotLocation?.headingSlam || props?.robotLocation?.heading)
      ) {
        // console.log(props?.robotLocation?.headingSlam, "HEADING");
        // // Marker type for with/without heading
        // robotMarkerDiv.style.backgroundImage = props?.robotLocation?.headingSlam
        //   ? "url(/assets/images/robot-icon-2.svg)"
        //   : "url(/assets/images/robot-icon-pickup_1.svg)";

        if (!!Number(process.env.REACT_APP_HEADING_ROTATION)) {
          robotMarker
            .setLngLat([
              parseFloat(props?.robotLocation?.longitudeSlam)
                ? parseFloat(props?.robotLocation?.longitudeSlam)
                : 0,
              parseFloat(props?.robotLocation?.latitudeSlam)
                ? parseFloat(props?.robotLocation?.latitudeSlam)
                : 0,
            ])
            .setRotation(parseFloat(props?.robotLocation?.headingSlam))
            .setRotationAlignment("map");
        } else {
          robotMarker
            .setLngLat([
              parseFloat(props?.robotLocation?.longitudeSlam)
                ? parseFloat(props?.robotLocation?.longitudeSlam)
                : 0,
              parseFloat(props?.robotLocation?.latitudeSlam)
                ? parseFloat(props?.robotLocation?.latitudeSlam)
                : 0,
            ])
            .setRotationAlignment("map");
        }
      } else {
        robotMarker
          .setLngLat([
            parseFloat(props?.robotLocation?.longitudeSlam)
              ? parseFloat(props?.robotLocation?.longitudeSlam)
              : 0,
            parseFloat(props?.robotLocation?.latitudeSlam)
              ? parseFloat(props?.robotLocation?.latitudeSlam)
              : 0,
          ])
          .setRotationAlignment("map");
      }
    }

    if (dropMarker) {
      dropMarker
        .setLngLat([
          parseFloat(props?.orderDropLocation?.longitude)
            ? parseFloat(props?.orderDropLocation?.longitude)
            : 0,
          parseFloat(props?.orderDropLocation?.latitude)
            ? parseFloat(props?.orderDropLocation?.latitude)
            : 0,
        ])
        .setRotationAlignment("map");

      // Set the content of the popup
      dropLocationPopup.setHTML(
        `<div class="custom-popup"><p>${
          props?.orderDropLocation?.locationName.includes("_")
            ? props?.orderDropLocation?.locationName
                .split("_")
                .slice(1)
                .join(" ")
            : props?.orderDropLocation?.locationName
        }</p></div>`
      );

      // Add the popup to the map at the specified coordinates
      dropLocationPopup.setLngLat([
        props?.orderDropLocation?.longitude
          ? props?.orderDropLocation?.longitude
          : 0,
        props?.orderDropLocation?.latitude
          ? props?.orderDropLocation?.latitude
          : 0,
      ]);
    }

    if (pickupMarker) {
      pickupMarker
        .setLngLat([
          parseFloat(props?.orderPickupLocation?.longitude)
            ? parseFloat(props?.orderPickupLocation?.longitude)
            : 0,
          parseFloat(props?.orderPickupLocation?.latitude)
            ? parseFloat(props?.orderPickupLocation?.latitude)
            : 0,
        ])
        .setRotationAlignment("map");
    }

    if (
      !userControllingMap &&
      !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
      props?.orderPickupLocation?.longitude &&
      props?.orderDropLocation?.longitude
    ) {
      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(
        [
          props?.orderPickupLocation?.longitude,
          props?.orderPickupLocation?.latitude,
        ],
        [
          props?.orderDropLocation?.longitude,
          props?.orderDropLocation?.latitude,
        ]
      );

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      // for (const coord of newrobotPath) {
      //   bounds.extend(coord);
      // }

      // bounds.extend([
      //   props?.orderDropLocation?.longitude,
      //   props?.orderDropLocation?.latitude,
      // ]);

      // if (destinationMarkerForBound.length !== 0) {
      //   bounds.extend(destinationMarkerForBound);
      // }

      map.fitBounds(bounds, {
        padding: 100,
        duration: 200,
        // bearing: robotDetails[robotId].headingSlam
        //   ? parseFloat(robotDetails[robotId].headingSlam)
        //   : 0,
      });
    }
  }, [
    props?.robotLocation?.longitudeSlam,
    robotMarker,
    props?.robotLocation?.headingSlam,
    props?.robotLocation?.latitudeSlam,
    props?.robotLocation?.heading,
    dropMarker,
    pickupMarker,
    props?.orderDropLocation?.latitude,
    props?.orderDropLocation?.longitude,
    props?.orderPickupLocation?.latitude,
    props?.orderPickupLocation?.longitude,
    userControllingMap,
    robotMarkerDiv.style,
    map,
    props?.orderDropLocation?.locationName,
    dropLocationPopup,
  ]);

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) && (
          <div
            className={`cursor-pointer flex items-center gap-2 sidebar bg-[#00B7D4] text-white p-[6px_12px] z-[1] absolute bottom-0 left-0 m-1 rounded-[4px] h-[30px]`}
            onClick={() => setUserControllingMap(false)}
          >
            <FiNavigation2 strokeWidth={3} />
            <span className="text-xs font-bold uppercase">Re-centre</span>
          </div>
        )}
      <div
        id="map-container-id"
        className="map-container"
        style={{
          height: "100%",
          position: "absolute",
          width: "100%",
        }}
      ></div>
    </div>
  );
}

export default MapboxRobot;
